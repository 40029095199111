interface Department {
  sales: { text: string; url: string };
  support?: { text: string; url: string };
}

interface PhoneNumbers {
  personalPhone: Department;
  businessPhone: Department;
  enterprisePhone: Department;
  wholesalePhone: Department;
}

export const getPhoneNumbers = (): PhoneNumbers => {
  return {
    personalPhone: {
      sales: {
        text: "1300\u00A0880\u00A0905",
        url: "tel:1300880905",
      },
      support: {
        text: "1300\u00A0543\u00A0986",
        url: "tel:1300543986",
      },
    },
    businessPhone: {
      sales: {
        text: "1300\u00A0480\u00A0905",
        url: "tel:1300480905",
      },
      support: {
        text: "1300\u00A0380\u00A0905",
        url: "tel:1300380905",
      },
    },
    enterprisePhone: {
      sales: {
        text: "1300\u00A0161\u00A0625",
        url: "tel:1300161625",
      },
    },
    wholesalePhone: {
      sales: {
        text: "1300\u00A0161\u00A0625",
        url: "tel:1300161625",
      },
    },
  };
};
